import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoginGuard } from './guards/login.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'create-password/:mailToken',
    loadChildren: () => import('./pages/create-password/create-password.module').then(m => m.CreatePasswordModule)
  },
  // {
  //   path: 'forgot-password',
  //   loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  // },
  {
    path: '',
    component: LayoutComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule),
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'partner-dashboard',
        loadChildren: () => import('./pages/partner-users-dashboard/partner-users-dashboard.module').then(m => m.PartnerUsersDashboardModule),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'state',
        loadChildren: () => import('./pages/state-management/state-management.module').then(m => m.StateManagementModule),
      },
      {
        path: 'plan-management',
        loadChildren: () => import('./pages/plan-management/plan-management.module').then(m => m.PlanManagementModule),
      },
      {
        path: 'lead',
        loadChildren: () => import('./pages/lead-management/lead-management.module').then(m => m.LeadManagementModule),
      },
      {
        path: 'homeowner',
        loadChildren: () => import('./pages/homeowner-hub/homeowner-hub.module').then(m => m.HomeownerHubModule),
      },
      {
        path: 'lead-partners',
        loadChildren: () => import('./pages/lead-partners/lead-partners.module').then(m => m.LeadPartnersModule),
      },
      {
        path: 'customer-agreement',
        loadChildren: () => import('./pages/customer-agreement/customer-agreement.module').then(m => m.CustomerAgreementModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'security',
        loadChildren: () => import('./pages/security-management/security-management.module').then(m => m.SecurityManagementModule),
      },
      {
        path: 'reputation',
        loadChildren: () => import('./pages/reputation/reputation.module').then(m => m.ReputationModule),
      },
      {
        path: 'audience',
        loadChildren: () => import('./pages/audience-management/audience-management.module').then(m => m.AudienceManagementModule),
      },
      {
        path: 'partners',
        loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule),
      },
      {
        path: 'file-claim',
        loadChildren: () => import('./pages/file-claim/file-claim.module').then(m => m.FileClaimModule),
      },
      {
        path: 'consumers',
        loadChildren: () => import('./pages/consumers/consumers.module').then(m => m.ConsumersModule),
      },
      {
        path:'customer-dashboard/:id',
        loadChildren:()=> import('./pages/customer-dashboard/customer-dashboard.module').then(m=>m.CustomerDashboardModule)
      },
      {
        path: 'customer-service-agreement',
        loadChildren: () => import('./pages/customer-service-agreement/customer-service-agreement.module').then(m => m.CustomerServiceAgreementModule),
      },
      {
        path: 'agent-dashboard',
        loadChildren: () => import('./pages/agent-dashboard/agent-dashboard.module').then(m => m.AgentDashboardModule),
      },
      {
        path: 'charge-copay',
        loadChildren: () => import('./pages/charge-copay/charge-copay.module').then(m => m.ChargeCopayModule),
      },
      {
        path: 'contacts',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
      },
    ]
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
